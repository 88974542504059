import { Container, Button,Form, Alert} from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";

import React from 'react';

class Patient extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          count:0,
          resultText :'Hej',
          resultVariant :"success",
          resultVisible:false,
          formDataCity:''        
        }
    }

    componentDidMount() {
        //CheckAccessToken;
    }   

    render() {
    return (
        <ContentWrapper>
            <Container>
            <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Journal</Rubrik>
                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 

                {CheckAccessToken() ? ( 
                    <>
                        <Stycke>Journalöversikt<br></br>• Journaltext<br></br>• Bifogade filer<br></br>• Bifogade bilder (DICOM)<br></br>• Ljudfiler (Diktat)<br></br></Stycke>
                    </>             
                ) : ( 
                    <>
                    </>             
                )}

            </Sida>
            </Container>
        </ContentWrapper>
    );
  }
}

export default Patient;