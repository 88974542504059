import { Container, Button,Form, Alert, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import useBackend from '../../services/useBackend';
import { FaProjectDiagram } from 'react-icons/fa';

import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";

import React from 'react';

function anchorClickHandler (event)   {
    const ReportGUID = event.currentTarget.getAttribute("ReportGUID")
    window.location = "/Reports/"+ReportGUID;
}

export default function LockInfo() {

    const {loading:loadinit, data:ReportData} =  useBackend('/api/v1/Report' );  

    if (!loadinit) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida>
                        <StyckeCompact>
                            <Rubrik>Rapporter</Rubrik>                        
                        </StyckeCompact>

                        <StyckeCompact>
                            <Table responsive="sm">
                                <tbody>
                                    {ReportData.Reports.map((Report) => {
                                        return (
                                            <tr>
                                                <td>{Report.Name}</td> <td>{Report.Description}</td> <td><span className='sub' ReportGUID={Report.Guid} onClick={anchorClickHandler}><FaProjectDiagram /></span></td>
                                            </tr>
                                        )
                                    })}                           
                                </tbody>
                            </Table>
                        </StyckeCompact>                    
                    </Sida>
                </Container>
            </ContentWrapper>
        )
        } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar info..</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}