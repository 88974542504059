import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,Stycke,ContentWrapper} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import {Tabs, Tab} from 'react-bootstrap-tabs';

import {XLogOff, Pageheight} from "../../index";

import React, { useState ,useEffect} from 'react';

import Settings from '../../data/settings.json';


export default function Login() {

  const [resultText, setResultText] = useState(":)");
  const [resultVariant, setResultVariant] = useState("success");
  const [resultVisible, setResultVisible] = useState(false);
  const [spinnerHidden, setSpinnerHidden] = useState(true);  

  const [resultTextBankID, setResultTextBankID] = useState(":)");
  const [resultVariantBankID, setResultVariantBankID] = useState("success");
  const [resultVisibleBankID, setResultVisibleBankID] = useState(false);
  const [spinnerHiddenBankID, setSpinnerHiddenBankID] = useState(true);  

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: (window.innerHeight-500)
  });

  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: (window.innerHeight-500)
    });
  }
  useEffect(() => (window.onresize = updateSize), []);  
  useEffect(() => (window.onscroll = updateSize), []);  

  function handleSubmit(e) {
    e.preventDefault();

    setSpinnerHidden(false);
    setResultVisible(true);

    const formData = new FormData(e.target),
    formDataObj = Object.fromEntries(formData.entries())

    var PostData = {SystemID:"IX", AuthData : formDataObj};
    
    // https://www.qmenow.com/backend/forms/contact
    fetch('/backend/auth/password', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json'
        },

        body: JSON.stringify(PostData),
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException==false) {
            setResultVariant("success");      
            setResultText(data.resultText);        
            setSpinnerHidden(false);
            setResultVisible(false);
            localStorage.setItem('Accesstoken', data.access_token);
            localStorage.setItem('isLoggedIn', true);
            //return <Redirect to='/mypage' />

            setTimeout(function() {
              window.location.href = "/mypage";
            }, 1400);
            
            //this.props.history.push('/mypage');
        } else {
            setResultVariant("warning");
            setResultText(data.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }
     })
     .catch(error => {
        setResultVariant("warning");
        setResultText("Ett fel uppstod!");
        setSpinnerHidden(true);
        setResultVisible(true);
      });
    }

	function handleSubmitBankID(e) {
		e.preventDefault();
	
		setSpinnerHiddenBankID(false);
		setResultVisibleBankID(true);
	
		const formData = new FormData(e.target),
		formDataObj = Object.fromEntries(formData.entries())
	
		var PostData = {SystemID:"IX", AuthData : formDataObj};
		
		fetch('/backend/auth/bankID', {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json'
			},
	
			body: JSON.stringify(PostData),
		 }).then(response => response.json())
		 .then(data => {
			if (data.errorsException==false) {
				setResultVariantBankID("success");      
				setResultTextBankID(data.resultText);        
				setSpinnerHiddenBankID(false);
				setResultVisibleBankID(false);
				localStorage.setItem('access_token', data.access_token);
				localStorage.setItem('isLoggedIn', true);
				//return <Redirect to='/mypage' />
	
				setTimeout(function() {
				  window.location.href = "/mypage";
				}, 1400);
				
				//this.props.history.push('/mypage');
			} else {
				console.log("..");
				setResultVariantBankID("warning");
				setResultTextBankID(data.resultText);
				setSpinnerHiddenBankID(true);
				setResultVisibleBankID(true);
				console.log("....");

			}
		 })
		 .catch(error => {
			setResultVariantBankID("warning");
			setResultTextBankID("Ett fel uppstod!");
			setSpinnerHiddenBankID(true);
			setResultVisibleBankID(true);
		  });
	}	

	function handleSubmitOnPrem(e) {
		e.preventDefault();
	
		setSpinnerHidden(false);
		setResultVisible(true);
	
		const formData = new FormData(e.target),
		formDataObj = Object.fromEntries(formData.entries())
	
		var PostData = {SystemID:"IX", AuthData : formDataObj};
		
		// https://www.qmenow.com/backend/forms/contact
		fetch('/backend/auth/password', {
			method: 'POST',
			headers: {
			   'Content-Type': 'application/json'
			},
	
			body: JSON.stringify(PostData),
		 }).then(response => response.json())
		 .then(data => {
			if (data.errorsException==false) {
				setResultVariant("success");      
				setResultText(data.resultText);        
				setSpinnerHidden(false);
				setResultVisible(false);
				localStorage.setItem('Accesstoken', data.accessToken);
				localStorage.setItem('access_token', data.access_token);
				localStorage.setItem('isLoggedIn', true);
				//return <Redirect to='/mypage' />
	
				setTimeout(function() {
				  window.location.href = "/mypage";
				}, 1400);
				
				//this.props.history.push('/mypage');
			} else {
				setResultVariant("warning");
				setResultText(data.ResultText);
				setSpinnerHidden(true);
				setResultVisible(true);
			}
		 })
		 .catch(error => {
			setResultVariant("warning");
			setResultText("Ett fel uppstod!");
			setSpinnerHidden(true);
			setResultVisible(true);
		  });
		}	

	function ResetForms(dirtyFormID) {
		console.log("Reset form: " + dirtyFormID);
		document.getElementById("FrmAuth").reset();
		setResultVisibleBankID(false);
		setResultVisible(false);
		setResultText("");
		setResultTextBankID("");
	}


  return (
    <ContentWrapper>
    <Container>
        <Sida style={{minHeight:Pageheight(400)}}>
        <Rubrik>Login</Rubrik>

        <Tabs   contentStyle={{backgroundColor:'#ccc'}} 
                headerStyle={{backgroundColor:'#eee' , color:'#444'}} 
				activeHeaderStyle={{backgroundColor:'#ccc', fontWeight: 'bold'}} 
                onSelect={(id, label) => ResetForms(label)}
        >

			<Tab label="BankID">
				<div style={{marginLeft:20, marginRight:20}}>
					<br></br>
				<Form onSubmit={handleSubmitBankID} id ="FrmAuth">

					<Form.Group className="mb-3">
						<Form.Label className="FormHeaderText">Personnummer</Form.Label>
						<Form.Control type="text" name="ssn" />
					</Form.Group>

					<Form.Group className="mb-3"  hidden={!resultVisibleBankID}>
						<Alert show={resultVisibleBankID} variant={resultVariantBankID}  className="FormSpace_">{resultTextBankID}</Alert> 
					</Form.Group>  

					<Form.Group className="mb-3"  hidden={spinnerHiddenBankID}>
						<SpinnerX  />
					</Form.Group> 

					<Form.Group className="mb-3">
						<Button className="QmeButton" variant="primary" type="submit">Login</Button> 
						<img src="/images/BankID_logo.png" width="64"></img>
					</Form.Group>         

				</Form>
				<br></br>
				</div>
			</Tab>

			<Tab label="Lösenord">
				<div style={{marginLeft:20, marginRight:20}}>
				<br></br>

				<Form onSubmit={handleSubmitOnPrem} id ="FrmAuth" >

					<Form.Group className="mb-3">
						<Form.Label className="FormHeaderText">Email</Form.Label>
						<Form.Control autoFocus type="text" name="username" id="FldUsername"/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label className="FormHeaderText">Password</Form.Label>
						<Form.Control type="password" name="password" id="FldPassword"/>
					</Form.Group>

					<Form.Group className="mb-3"  hidden={!resultVisible}>
						<Alert show={resultVisible} variant={resultVariant}  className="FormSpace_">{resultText}</Alert> 
					</Form.Group>  

					<Form.Group className="mb-3"  hidden={spinnerHidden}>
						<SpinnerX  />
					</Form.Group> 

					<Form.Group className="mb-3">
						<Button className="QmeButton" variant="primary" type="submit">Login</Button>
					</Form.Group>         

					<Form.Group className="mb-3">
						<a href="/password/request/new" className="text-decoration-none"  style={{color:'#000', fontSize:'16px'}}>Glömt lösenord</a>
					</Form.Group>                       

				</Form>
				<br></br>
				</div>
			</Tab>			
        </Tabs>

                
      </Sida>
      </Container>
      </ContentWrapper>
  );
}