import '../App.css';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import React, { useState, Component } from 'react';
import {  useNavigate } from 'react-router';
import { StyckeCompact, Stycke, StyckeBox, Rubrik } from './ContentStyles';
import { AppColors } from '..';

const Prices = () => {
    let navigate = useNavigate();

    function handleSubmitProd1(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=IX10'); 
    }    

    function handleSubmitProd2(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=IX20'); 
    }    
    
    function handleSubmitProd3(e) {
        e.preventDefault();
        navigate('/signup?defaultproduct=IX30'); 
    }    
    
    function handleSubmitProd4(e) {
        e.preventDefault();
        navigate('/contact'); 
    }        

    return (
    <>
        <PricingTable highlightColor={AppColors.base}>

            <PricingSlot onClick={handleSubmitProd1}   buttonText='SIGN UP' title='Cloud Basic' priceText='0:-/månad' >
                <PricingDetail> <b>BankId</b> Ja</PricingDetail> 
                <PricingDetail> <b>Tillgång till API</b> Nej</PricingDetail>                  
                <PricingDetail> <b>Användare</b> 0st</PricingDetail>
                <PricingDetail> <b>Containers</b> 1st</PricingDetail>
                <PricingDetail> <b>Automatisk gallring</b>Nej</PricingDetail>
                <PricingDetail> <b>Support</b> Mail 09:00-16:00</PricingDetail>        
            </PricingSlot>

            <PricingSlot onClick={handleSubmitProd2} highlighted  buttonText='SIGN UP' title='Cloud Standard' priceText='2500:-/månad' >
                <PricingDetail> <b>BankId</b> Ja</PricingDetail>
                <PricingDetail> <b>Tillgång till API</b> Nej</PricingDetail>
                <PricingDetail> <b>Användare</b> 5st</PricingDetail>
                <PricingDetail> <b>Containers</b> 3st</PricingDetail>
                <PricingDetail> <b>Automatisk gallring</b>Ja</PricingDetail>
                <PricingDetail> <b>Support</b> Mail 09:00-16:00</PricingDetail>
            </PricingSlot>            

            <PricingSlot onClick={handleSubmitProd3}   buttonText='SIGN UP' title='Cloud Professional' priceText='5000:-/månad'>
                <PricingDetail> <b>BankId</b> Ja</PricingDetail>
                <PricingDetail> <b>Tillgång till API</b> Ja</PricingDetail>
                <PricingDetail> <b>Användare</b> 10st</PricingDetail>
                <PricingDetail> <b>Containers</b> 5st</PricingDetail>
                <PricingDetail> <b>Automatisk gallring</b>Ja</PricingDetail>
                <PricingDetail> <b>Support</b> Mail 09:00-16:00</PricingDetail>
            </PricingSlot>

            <PricingSlot onClick={handleSubmitProd4}   buttonText='Kontakta oss' title='OnPrem' priceText='5000:-/månad'>
                <PricingDetail> <b>BankId</b> Ja</PricingDetail> 
                <PricingDetail> <b>Tillgång till API</b> Ja</PricingDetail>                  
                <PricingDetail> <b>Användare</b> 0st</PricingDetail>
                <PricingDetail> <b>Containers</b> 10st</PricingDetail>
                <PricingDetail> <b>Automatisk gallring</b>Ja</PricingDetail>
                <PricingDetail> <b>Support</b> Mail 09:00-16:00</PricingDetail>
            </PricingSlot>            
        
        </PricingTable>

        <StyckeBox>
            <p style={{fontSize: 20}}>Volymkostnader</p>
            <table >
                <tr> <td>BankID </td>                       <td>7500:-</td>             <td>Uppstart</td>       </tr>
                <tr> <td>BankID </td>                       <td>0.25:-</td>             <td>Autentisering</td>  </tr>
                <tr> <td>Extra användare&nbsp;&nbsp;</td>   <td>150:-&nbsp;&nbsp;</td>  <td>Månad</td>          </tr>                
                <tr> <td>Extra container</td>               <td>1000:-&nbsp;&nbsp;</td> <td>Månad</td>          </tr>                
                <tr> <td>Bulkmigrering</td>                 <td>1:-</td>                <td>Objekt</td>         </tr>
                <tr> <td>Lagring-Cloud</td>                 <td>0.25:-</td>             <td>Mb/Månad</td>       </tr>
                <tr> <td>Lagring-OnPrem</td>                <td>0.01:-</td>             <td>Objekt/Månad</td>   </tr>
            </table>
            <StyckeCompact>Priser angivna exklusive mervärdesskatt.</StyckeCompact>
            <StyckeCompact>För 2023 tillkommer 7% på ovanstående priser.</StyckeCompact>
        </StyckeBox>
        </>
  )
};

export default Prices;        