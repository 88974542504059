import '../App.css';
import { Container,Table} from 'react-bootstrap';
import SpinnerX from './SpinnerX';
import Faq from "./Faq";
import React, { useState, Component } from 'react';
import {Sida,Rubrik,Stycke,ContentWrapper, Link, StyckeBox ,StyckeCompact} from "./ContentStyles";
import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../index";
import Prices from "./Prices";
import useBackend from '../services/useBackend';
import Settings from '../data/settings.json';

export default function LockInfo() {

    const {loading:loadinit, data:LicenseData} =  useBackend('/api/License' );

    if (!loadinit) {
      return (
        <ContentWrapper>
    
        <Container>
        <Sida>          
          <Rubrik>Om Indexify</Rubrik>
          
          <Stycke>
            Indexify® digitaliserar, arkiverar, offentliggör och tillgängliggör information skalbart, säkert och spårbart. Indexify® finns till 100% på svensk mark under svensk lagstiftning, tanken är att du ska känna dig trygg med var du sparar din information.
          </Stycke>

          <Stycke>
            Med Indexify® strukturerar du din data/information i containrar med olika egenskaper och funktioner, tex kan en container för att arkivera fakturor fungera på ett sätt medans ett bildarkiv eller personuppgiftregister kan fungera på ett annat sätt.<br></br>
            Gemensamt för alla containertyper är att din organisation enkelt kan få en bild av vem och när en informationsmängd hanterats.
          </Stycke>          

          {!Settings.isOnPrem && <Stycke>
            <Link href="/contact?subject=Demo">Boka demo</Link>
          </Stycke>}

          {!Settings.isOnPrem &&<Rubrik>Abonnemang</Rubrik>}
          {!Settings.isOnPrem &&<Prices />}
          {!Settings.isOnPrem &&<Faq />}

          {Settings.isOnPrem &&
            <>
              <Rubrik>Versionsinformation</Rubrik>
              <Table responsive="sm">
                  <tbody>
                              <>
                              <tr> <th><small>Produkt</small></th> <td><small>Indexify® OnPrem</small></td></tr>
                              <tr> <th><small>Version GUI</small></th> <td><small>1.7</small></td></tr>
                  <tr> <th><small>Supporterad till</small></th> <td ><small>2023-02-28</small></td></tr>
                  <tr> <th><small>Version Backend</small></th> <td><small>1.0</small></td></tr>
                  <tr> <th><small>Supporterad till</small></th> <td><small>2024-12-31</small></td></tr>
                              </>
                       
                  </tbody>
              </Table>

              <Rubrik>Licensiering</Rubrik>
              <Table responsive="sm">
                  <tbody>
                              <>
                              <tr> <th><small>Produkt</small></th> <td><small>Indexify® OnPrem</small></td></tr>
                              <tr> <th><small>Kund</small></th> <td><small>{LicenseData.License.Customer}</small></td></tr>
                              <tr> <th><small>OrganisationID</small></th><td><small>{LicenseData.License.OrganisationID}</small></td> </tr>
                              <tr> <th><small>Kontakt</small></th><td><small>{LicenseData.License.Contact}</small></td> </tr>

                              
                              <tr> <th><small>Giltig tom</small></th><td><small>{LicenseData.License.Expires}</small></td> </tr>
                              <tr> <th><small>Max antal anrop/timme</small></th><td><small>{LicenseData.License.MaxReqHour}</small></td> </tr>
                              <tr> <th><small>Max antal object</small></th><td><small>{LicenseData.License.MaxQtyObjects}</small></td> </tr>
                              </>
                       
                  </tbody>
              </Table>
            
            </>
          }

        </Sida>    
        </Container>
        </ContentWrapper>
      )
      } else {
      return (
          <ContentWrapper>
              <Container>
                  <Sida style={{minHeight:Pageheight(400)}}>
                  <Rubrik>Laddar info..</Rubrik>
                  <SpinnerX  />
              </Sida>
              </Container>
          </ContentWrapper>
      );        
  }
}