import { Container, Button,Form, Alert, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import useBackend from '../../services/useBackend';
import { FaProjectDiagram } from 'react-icons/fa';

import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";

import React from 'react';

function anchorClickHandler (event)   {
    const ContainerGUID = event.currentTarget.getAttribute("ContainerGUID")
    window.location = "/Container/"+ContainerGUID;
}

export default function LockInfo() {

    const {loading:loadinit, data:ContainerData} =  useBackend('/api/v1/Container' );  

    if (!loadinit) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida>
                        <StyckeCompact>
                            <Rubrik>Containers</Rubrik>                        
                        </StyckeCompact>

                        <StyckeCompact>
                            <Table responsive="sm">
                                <tbody>
                                    {ContainerData.Containers.map((Container) => {
                                        return (
                                            <tr>
                                                <td>{Container.Name}</td> <td>{Container.Description}</td> <td><span className='sub' ContainerGUID={Container.Guid} onClick={anchorClickHandler}><FaProjectDiagram /></span></td>
                                            </tr>
                                        )
                                    })}                           
                                </tbody>
                            </Table>
                        </StyckeCompact>                    
                    </Sida>
                </Container>
            </ContentWrapper>
        )
        } else {
        return (
            <ContentWrapper>
                <Container>
                    <Sida style={{minHeight:Pageheight(400)}}>
                    <Rubrik>Laddar info..</Rubrik>
                    <SpinnerX  />
                </Sida>
                </Container>
            </ContentWrapper>
        );        
    }
}