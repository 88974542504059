import { Container, Button,Form, Alert, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import useBackend from '../../services/useBackend';

import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";

import React, { useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';


export default function LockInfo() {

    // const [loading, setLoadning] = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const navigate = useNavigate();    

    const {ContainerGUID} = useParams();

    const {loading:loadinit, data:ContainerData} =  useBackend('/api/v1/Container/'+ ContainerGUID + '' );  


  
    if (!loadinit) {
      return (
        <ContentWrapper>
            <Container>
                <Sida>
                    <StyckeCompact>
                        <Rubrik>{ContainerData.Container.Name}</Rubrik>                        
                        {ContainerData.Container.Description}
                    </StyckeCompact>

                    <StyckeCompact>

                    </StyckeCompact>                    
                </Sida>
            </Container>
        </ContentWrapper>
      )
    } else {
      return (
          <ContentWrapper>
              <Container>
                  <Sida style={{minHeight:Pageheight(400)}}>
                  <Rubrik>Laddar info..</Rubrik>
                  <SpinnerX  />
              </Sida>
              </Container>
          </ContentWrapper>
      );        
    }
  
  }