import { Container, Button,Form, Alert, Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke, StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import useBackend from '../../services/useBackend';

import {Pageheight, CheckAccessToken, XMenu, GetAccessToken} from "../../index";

import React, { useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';


export default function LockInfo() {

    // const [loading, setLoadning] = useState(false);
    // const [resultVariant, setResultVariant] = useState('warning');
    const [resultText, setResultText] = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);    

    const navigate = useNavigate();    

    const {ReportGUID} = useParams();

    const {loading:loadinit, data:ReportData, resultVariant} =  useBackend('/api/v1/Report/'+ ReportGUID + '' );  

    // var ReportData = require('../../data/kristina.json');

  
    if (!loadinit) {

      if (resultVariant=='success')
      return (
        <ContentWrapper>
            <Container>
                <Sida>
                    <StyckeCompact>
                        <Rubrik>{ReportData.Report.Name}</Rubrik>                        
                        {ReportData.Report.Description}
                    </StyckeCompact>

                    <StyckeCompact>
                        <Table responsive="sm">
                            <thead>
                                <tr> 
                                {ReportData.ReportHeader.map((item) => {
                                    return <td><b>{item}</b></td>
                                })}
                                </tr>
                            </thead>

                            <tbody>
                                {ReportData.ReportRows.map((key) => {
                                    return (
                                        <tr>
                                            {key.map((dataItem) => {
                                                return (
                                                <td>{dataItem}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}                           

                            </tbody>
                        </Table>
                    </StyckeCompact>                    
                </Sida>
            </Container>
        </ContentWrapper>
      )
    } else {
      return (
          <ContentWrapper>
              <Container>
                  <Sida style={{minHeight:Pageheight(400)}}>
                  <Rubrik>Laddar info..</Rubrik>
                  <SpinnerX  />
              </Sida>
              </Container>
          </ContentWrapper>
      );        
    }
  
  }