import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper, Stycke} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight,XLogOff} from "../../index";

import React from 'react';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:true
        }
    }

    CheckAccessToken;

    handleSubmit = (e) =>{
        e.preventDefault();

        this.setState({ resultVariant :'warning', resultVisible:false, resultText: '', spinnerHidden:false  });
        var PostData = {SystemID:"Q"};          
        
        fetch('/backend/instance/Terminate', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: JSON.stringify(PostData),
            }).then(response => response.json())
            .then(data => {
                if (data.errorsException===false) {
                    localStorage.setItem('access_token', "");
                    localStorage.setItem('isLoggedIn', true);
                    
                    this.setState({ resultVariant :'success', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });

                    setTimeout(function() {
                        window.location.href = "/login";
                    }, 2500);
                    
                } else {
                    this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                }
            })
            .catch(error => {
    
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
    
        });
    }  

    componentDidMount() {}

    render() {
    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Avsluta konto</Rubrik>

                <Stycke>OBS Efter att du avslutat ditt konto så kommer dina tjänster inte längre att vara tillgängliga. För att åter få tillgång till våra tjänster behöver du skapa ett nytt konto.</Stycke>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                        <SpinnerX  />
                    </Form.Group>                      

                    <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                        <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                    </Form.Group>        

                    <Form.Group className="mb-3">
                        <Button className="QmeButton" variant="primary" type="submit">Avsluta konto</Button>
                    </Form.Group>    
                </Form>     
                
            </Sida>
            </Container>
        </ContentWrapper>
    );
  }
}

export default MyComponent;