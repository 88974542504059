import { useState ,useEffect} from 'react';

export default function useGetBackend(url) {
    const [data, setData] = useState([]);
    const [loading, setLoadning] = useState(true);

    const [resultVariant, setResultVariant] = useState(null);
    const [resultText, setResultText] = useState(null);
    const [resultVisible, setResultVisible] = useState(null);

    useEffect(() => {

        async function init() {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accessToken':localStorage.getItem('access_token'),
                    },
                });

                if (response.ok) {
                    const json = await response.json();
                    setResultVariant('success');
                    setResultVisible(false);
                    setResultText('');
                    setData(json);
                } else {
                    setResultVariant('warning');
                    setResultVisible(true);
                    setResultText('Ett fel uppstod!!');

                    throw response;
                }
            }
            catch (e) {
                setResultVariant('warning');
                setResultVisible(true);
                setResultText('Ett fel uppstod!!!');

            }
            finally {
                setLoadning(false);
            }
        }
        init();

    }, [url]);  

    return { resultVariant, resultVisible, resultText, loading, data }; 
}

export async function CallAPI(url, method = 'GET', body = {}) {
    var resultVariant, resultVisible, resultText="...";

    try {
        const response =  await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: body
        });

        if (response.ok) {
            const json =  await response.json();
            
            resultVisible=true;
            resultText=json.resultText;

            if (json.errorsException) {
                resultVariant ="warning";    
            } else {
                resultVariant ="success";
            }

        } else {
            resultVariant ="warning";
            resultVisible=true;
            resultText= 'Ett fel uppstod!';
        }
    }
    catch (e) {
        resultVariant ="warning";
        resultVisible=true;
        resultText= 'Ett fel uppstod!!';
    }
    finally {

    }
    return [ resultVariant, resultVisible, resultText ]; 
}


export function IsLoggedIn(token) {
    var url = "backend/auth/token/"+token+"/isValid";

    async function init() {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log("IsLoggedIn: true");
                return true;
            } else {
                console.log("IsLoggedIn: false");
                return false;
            }
        }
        catch (e) {
            return false;

        }
        finally {
            //setLoadning(false);
        }
    }
    init();
    return false;
}

export function XLogOff() {
    localStorage.setItem('access_token', "");
    localStorage.setItem('isLoggedIn', true);
    window.location.href = "/login";
}

// export function GetAccessToken() {
//     var access_token = localStorage.getItem('access_token');
  
//     return access_token;
//   }
  
//   export function CheckAccessToken() {
//     var access_token = localStorage.getItem('access_token');
  
//     if (access_token==="") {
//       window.location.href = "/login";
//       return false;
//     } else {
//       return true;
//     }
//   }
  
  export function CheckIfInlogged() {
    var access_token = localStorage.getItem('access_token'); 
  
    if (access_token==="" || access_token === null) {
      return false;
    } else {
      return true;
    }
  }